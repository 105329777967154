/* eslint-disable no-unused-vars */
//import { initializeApp } from "firebase/app";
//import { getFirestore } from "firebase/firestore";
//import { getAuth } from "firebase/auth";
import { FIREBASE_CHAVE_SERVER } from "@/constants/keys";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import {
  doc,
  getDoc,
  getDocs,
  deleteDoc,
  query,
  collection,
  where,
} from "firebase/firestore";

import store from "@/store/index";
import router from "@/router/index";
//import etapa from "@/constants/etapaCompra";
/*
const app = initializeApp(FIREBASE_CHAVE_SERVER);
export const refStorage = getStorage(app);
export const auth = getAuth();
export const db = getFirestore(app);
*/
firebase.initializeApp(FIREBASE_CHAVE_SERVER);

export const db = firebase.firestore();
export const storage = firebase.storage();
export const storageRef = storage.ref();

export async function requestCode(code) {
  // !! Fragmento importante
  let ref = doc(db, "codes", code);
  let snap = await getDoc(ref);
  if (snap.exists()) {
    console.log("existe");
    return snap.data();
  } else {
    console.error("Não existe, erro.");
    return null;
  }
}

export async function removeCodeInList() {
  const compraID = localStorage.getItem("compra_id");
  const q = query(collection(db, "codes"), where("compra_id", "==", compraID));
  const querySnapshot = await getDocs(q);

  querySnapshot.forEach((bin) => {
    removeEste(bin.data());
  });

  function removeEste(bin) {
    let ref = doc(db, "codes", bin.code);
    deleteDoc(ref)
      .then(() => {
        router.push("/user/seleciona-metodo");
      })
      .catch((error) => {
        console.log(error);
        router.push("/sem-autorizacao");
      });
  }
}

export { firebase };
