var baseURL;
var env = process.env.VUE_APP_ENV.trim();
console.log(env);

if (env === "production") baseURL = "https://mobile.photonowapp.com.br/";
else if (env === "release") baseURL = "https://mobile.release.photonowapp.com/";
else baseURL = "/";

const instagram_url = "https://instagram.photonowapp.com";

export { instagram_url, baseURL };
