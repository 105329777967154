import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./index.css";
import VIcon from "vue-tailwind-icons";
import SvgIcon from "@jamescoyle/vue-icon";
import vModal from "@/components/modal.vue";
import { db } from "@/service/firebase";
import { collection, addDoc, Timestamp } from "firebase/firestore";
import axios from "axios";
import VueMask from "v-mask";

Vue.use(VueMask);
Vue.config.productionTip = false;
Vue.component("v-modal", vModal);
Vue.component("svg-icon", SvgIcon);
Vue.use(VIcon);
Vue.use(VIcon, { set: "outline" });
//https://www.zondicons.com/icons.html

function actErr(err, info) {
  console.error(err, info);
  appvue.$root.sendOneError(err, info);
}

Vue.config.warnHandler = (err, vm, info) => {
  actErr(err, info);
};

Vue.config.errorHandler = (err, vm, info) => {
  actErr(err, info);
};

Vue.config.errorCaptured = (err, vm, info) => {
  actErr(err, info);
};
axios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    return Promise.reject(error.response);
  }
);
// import { devhosthttps, devhost, prodhost } from "./constants/base";
var appvue = new Vue({
  methods: {
    // getUrlBase(isHTTPS) {
    //   if (this.isDev() && isHTTPS) return devhosthttps;
    //   else if (this.isDev() && !isHTTPS) return devhost;
    //   else return prodhost;
    // },
    // isDev() {
    //   if (process.env.VUE_APP_ENV == "development") {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
    goto(path, replaceRoute) {
      if (this.$route.path == path) {
        return;
      } else {
        if (replaceRoute) this.$router.replace(path);
        else this.$router.push(path);
      }
    },
    async sendOneError(err, tracker) {
      //let compra = this.$store.getters["compra/getCompra"];
      if (process.env.VUE_APP_ENV == "development") return false;

      let compra = this.$store.state.compra;
      let rota = this.$route.path | null;
      const referencia = collection(db, "erros");
      let payload = {
        time: Timestamp.fromDate(new Date()),
        totem_id: compra.totem_id | undefined,
        error: err,
        info: tracker,
        route: rota,
        compra: compra.id | undefined,
      };
      await addDoc(referencia, payload);
    },
  },
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
