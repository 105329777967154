import Vue from "vue";
import Vuex from "vuex";
import StoreShop from "./compra/index";
import StoreInstagram from "./instagram/index";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions: {},
  modules: {
    compra: StoreShop,
    instagram: StoreInstagram,
  },
});
