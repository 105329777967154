import Vue from "vue";
import VueRouter from "vue-router";
// import { baseURL } from "../constants/base";

Vue.use(VueRouter);

const routes = [
  // ! ROTAS SOMENTE PARA TESTES E APROVAÇÕES
  /*
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/instagram-review/login.vue"),
  },
  {
    path: "/user-instagram",
    name: "userInstagram",
    component: () => import("@/views/instagram-review/userInstagram.vue"),
  },
  */
  // ======== Rotas do sistema
  {
    path: "/",
    name: "Root",
    redirect: "/code",
  },
  {
    path: "/sem-autorizacao",
    name: "SemAutorizacao",
    component: () => import("@/views/SemAutorizacao.vue"),
  },
  {
    path: "/code",
    name: "code",
    component: () => import("@/views/code.vue"),
  },
  {
    path: "/init/:id",
    name: "init",
    component: () => import("@/views/init.vue"),
  },
  {
    path: "/user/auth-instagram",
    name: "authInstagram",
    component: () => import("@/views/user/instagramAuth.vue"),
    props: (route) => ({ query: route.query.code }),
  },
  {
    path: "/user/seleciona-metodo",
    name: "selecionaMetodo",
    component: () => import("@/views/user/SelecionaMetodo"),
  },
  {
    path: "/user",
    name: "userCabine",
    component: () => import("@/views/user/index"),
  },
  {
    path: "/user/fotos-instagram",
    name: "fotosInstagram",
    component: () => import("@/views/user/fotosInstagram"),
  },

  {
    path: "/user/upload-imagens",
    name: "uploadImagem",
    component: () => import("@/views/user/uploadFotos"),
  },
  {
    path: "/user/upload-sucesso",
    name: "uploadSucesso",
    component: () => import("@/views/user/uploadSucesso"),
  },
  {
    path: "/user/upload-falha",
    name: "uploadFalha",
    component: () => import("@/views/user/uploadFalha"),
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/404/index.vue"),
  },
  {
    path: "*",
    redirect: "/404",
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
